@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i');

$green: #40B28D;
$blue: dodgerblue;
//$fallback-bg: rgb(92, 176, 175);
$fallback-bg: rgb(89, 176, 193);

$font-small: 0.8rem;
$font-size-1: 1rem;
$font-size-1_5: 1.5rem;
$font-size-2: 2rem;
$font-size-3: 3rem;
$font-family: 'Roboto', sans-serif;

body{
  font-family: $font-family;
  color: white;
  background: $fallback-bg;
}

body.browser-modern {
  background: radial-gradient(ellipse at bottom left, rgba(28,179,234,1) 0%,rgba(64,178,141,1) 92%,rgba(64,178,141,1) 92%) fixed;
}

body, h1, h2, h3, h4, h5, h6, input[type='text'], input[type='number'] {
  font-weight: 100;
}

button.link {
  font-family: $font-family;
  font-size: 1em;
  text-align: left;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 100;
}

button.best-of, button.game-type {
  text-align: center;
  background: rgba(255,255,255,0.2);
  margin: 1rem 1rem 1rem 0;
  padding: 0.7rem 1rem;
  border: none;
  cursor: pointer;
  color: white;
  &.selected {
    background: dodgerblue;
  }
}
button.best-of{
  font-size: $font-size-1_5;
  font-weight: 300;
}
button.game-type {
  font-size: $font-size-1_5;
  font-weight: 100;
}

input[type='text'], input[type='number'] {
  font-family: $font-family;
  font-size: $font-size-2;
  padding: 0.5rem;
  color: #666;
}

nav {
  h1{
    font-size: $font-size-2;
    margin: 1rem 0;
    a{
      text-decoration: none;
    }
  }
  .dev-environment-alert {
    margin-left: 2rem;
    font-size: $font-size-1;
  }
  .dev-environment-danger {
    font-weight: 300;
    color: red;
  }
}
nav.main-menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      h2 {
        padding: 1rem 0;
        margin: 0;
        font-size: $font-size-3;
        text-transform: uppercase;
      }
      a {
        text-decoration: none;
      }
    }
  }
  ul {
    ul {
      list-style-position: inside;
      list-style-type: "» ";
    }
    ul li {
      font-size: $font-size-2;
    }
  }
}
p {
  opacity: 0.8;
}
a{
  color: white;
}

.container{
  display: flex;
}
.col {
  padding-right: 20px;
}

.select-player {
  h2, h3, h4, h5 {
    font-size: $font-size-2;
    opacity: 0.5;
    text-transform: uppercase;
    font-style: italic;
    margin: 1rem 0;
    padding: 0;
  }
  h5 {
    font-size: $font-size-1_5;
    text-transform: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-size: $font-size-3;
      padding: 0 0 1rem 0;
      margin: 0;
      a{
        text-decoration: none;
      }
    }
  }
  .active-player {
    opacity: 0.5;
    text-transform: uppercase;
    font-style: italic;
    font-size: $font-size-1;
    margin-left: 10px;
  }
}
.new-game, .active-game {
  h2 {
    font-size: $font-size-2;
    opacity: 0.5;
    text-transform: uppercase;
    font-style: italic;
    margin: 1rem 0;
    padding: 0;
  }
  .player-name {
  }
  .versus {
  }
  .player-name, .versus {
    padding-right: 1rem;
  }
}
button.start-game, a.start-game, button.end-game, a.end-game {
  font-size: $font-size-3;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem;
  font-style: italic;
  background: rgba(255,255,255,0.2);
  margin-top: 2rem;
}
button.end-game, a.end-game {
  margin-bottom: 2rem;
}
.series-timestamps{
  margin-top: 3rem;
}

.active-game {
  margin-bottom: 2rem;
  .player-points-possible {
    border-right: 1px solid white;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .player-points {
    background-color: white;
    color: $green;
    font-size: $font-size-3;
  }
  .player-points.no-points{
    color: white;
    opacity: 0.5;
  }
  .player-points-possible, .player-points {
    height: 4rem;
    user-select: none;
  }
  .points {
    .player-name {
      cursor: pointer;
      h2 {
        padding: 0;
        margin: 0;
        &.winner {
          color: white;
          opacity: 1;
        }
      }
    }
  }
}
// variable point games
.active-game.variable {
  display: flex;
  margin-bottom: 0;
  .scoreboard {
    width: 50%;

    .player-points {
      padding: 1rem 0;
      user-select: inherit;
    }
    .player-points, .player-team, .player-name {
      width: 90%;
      text-align: center;
    }
  }
}

table, tbody, tr, td, thead, th{
  border: none;
}
tbody tr:nth-child(odd){
  td.game-name, td.game-score, td.scoring-player {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.game-history {
  td {
    padding: 1rem 1rem 1rem 0;
  }
  .score {
    font-size: $font-size-1_5;
    text-transform: uppercase;
    font-style: italic;
    a {
      text-decoration: none;
    }
  }
  time {
    margin-left: 2rem;
  }
}
.goal-history {
  td, th {
    padding: 0.5rem;
    font-size: $font-size-1_5;
    &.player-name, &.goal-number {
      text-transform: uppercase;
      font-style: italic;
      font-weight: 300;
    }
    &.goal-number {
      opacity: 0.5;
    }
  }
}

.game-start-label {
  text-transform: uppercase;
  font-style: italic;
  opacity: 0.5;
}
.game-start-time {
  font-style: italic;
}
.goal-time {
  opacity: 0.5;
}
h2.leaderboard-section {
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 1rem;
  opacity: 0.6;
}
table.leaderboards {
  th{
    padding: 0 1rem;
    background-color: white;
    color: $green;
    opacity: 0.8;
    font-weight: 300;
  }
  td {
    padding: 1rem;
  }
}

.series-score, .game-score {
  .player-name {
    text-transform: uppercase;
    font-style: italic;
    font-weight: 300;
    margin-right: 0.5rem;
    small {
      opacity: 0.6;
    }
  }
  .player-score {
    margin-right: 2rem;
  }
  .best-of, .game-type {
    opacity: 0.6;
    font-style: italic;
  }
  .game-number {
    margin-right: 0.5rem;
  }
}
h1.series-score {
  font-size: 2rem;
}
.game-scores {
  margin-bottom: 2rem;
}
.list-series {
  h1.series-score {
    margin: 0;
  }
  h2.series-score {
    margin: 0 0 0.5rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0 0 1rem 0;
      margin: 0 0 1rem 0;
      .extended {
        opacity: 0.6;
        font-style: italic;
        span {
          margin-right: 1rem;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
}
.page-header {
  text-transform: uppercase;
  opacity: 0.6;
}

// overrides for Silk Browser on Kindle Fire tablet
body.browser-silk {
  background: $fallback-bg;
  &, h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  .active-game {
    .player-points {
      color: $fallback-bg;
    }
  }
}